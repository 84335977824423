import { Injectable } from '@angular/core';
import { AnalyticsBrowser, Analytics } from '@segment/analytics-next';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastService } from './toast-service/toast.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private analytics: Analytics | undefined;
  private analyticsReady = new BehaviorSubject<boolean>(false);

  constructor(private toastService: ToastService) {
    const writeKey = environment.analytics.segmentWriteKey;
    AnalyticsBrowser.load({ writeKey })
      .then(([analytics]) => {
        this.analytics = analytics;
        this.analyticsReady.next(true);
      })
      .catch((err) => this.toastService.onShow('error', err, true, 'error'));
  }

  private async ensureAnalytics(): Promise<void> {
    if (!this.analytics) {
      await firstValueFrom(this.analyticsReady.asObservable());
    }
  }

  async identify(userId: string, traits?: object): Promise<void> {
    await this.ensureAnalytics();
    this.analytics?.identify(userId, traits);
  }

  async track(event: string, properties?: object): Promise<void> {
    await this.ensureAnalytics();
    this.analytics?.track(event, properties);
  }

  async page(
    category?: string,
    name?: string,
    properties?: object
  ): Promise<void> {
    await this.ensureAnalytics();
    this.analytics?.page(category, name, properties);
  }
}
